<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
    @keydown.esc="goToOrdersPage"
  >
    <OrderForm
      :order="newOrder"
      :errors="orderValidationErrors"
      :disabled="$store.getters.loading['post:api/orders']"
      @clear:errors="clearOrderValidationErrors"
      @cancel="goToOrdersPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import OrderForm from '@/components/OrderForm';

export default {
  name: 'CreateOrder',

  components: { OrderForm },

  computed: mapState('orders', ['newOrder', 'orderValidationErrors', 'orderFilterParams']),

  created() {
    this.RESET_ORDER_VALIDATION_ERRORS();
  },

  methods: {
    ...mapMutations('orders', ['RESET_ORDER_VALIDATION_ERRORS']),
    ...mapActions('orders', ['storeOrder', 'clearOrderValidationErrors']),

    onSave(order) {
      this.storeOrder(order).then(() => {
        this.goToOrdersPage();
      });
    },

    goToOrdersPage() {
      this.$router.push({ name: 'orders', query: this.orderFilterParams }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
